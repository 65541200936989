import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { down } from 'styled-breakpoints'

import useTipsPath from '../hooks/useTipsPath'

const TipLink = styled(Link)`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.white};
    border-radius: ${theme.round.large};
    box-shadow: 0 0 2px 2px ${theme.colors.neutral.gray300};
    overflow: hidden;
    transition: transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  `};
`

const TipWrapper = styled.article`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const TipTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray1200};
    border-top: 1px solid ${theme.colors.neutral.gray200};
    padding: ${theme.spacers.normal};

    ${down('small')} {
      font-size: ${theme.fontSizes.medium};
    }
  `}
`

const TipExcerpt = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray900};
    padding: 0 ${theme.spacers.normal};
    font-size: ${theme.fontSizes.small};
    margin-top: auto;

    ${down('small')} {
      font-size: ${theme.fontSizes.normal};
    }
  `}
`

export default function TipItem({
  tip: {
    excerpt,
    fields: { slug },
    frontmatter: { title, image },
  },
}) {
  const { getTipPath } = useTipsPath()

  return (
    <TipLink to={getTipPath(slug)}>
      <TipWrapper>
        <Img fluid={image.childImageSharp.fluid}></Img>
        <TipTitle>{title}</TipTitle>
        <TipExcerpt>{excerpt}</TipExcerpt>
      </TipWrapper>
    </TipLink>
  )
}
