import React from 'react'
import { graphql } from 'gatsby'

import Tips from '../components/Tips'

const TipsTemplate = ({ data: { allMdx }, pageContext }) => {
  const { edges: tips } = allMdx
  const { currentPage, numPages } = pageContext

  return (
    <Tips tips={tips} currentPage={currentPage} totalPages={numPages}></Tips>
  )
}

export const pageQuery = graphql`
  query TipsQuery($skip: Int!, $limit: Int!) {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/content/tips/" } }
      sort: { fields: frontmatter___createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 50, webpQuality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default TipsTemplate
