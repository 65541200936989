import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO'
import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash'
import PageTitle from '@babelcoder/gatsby-theme-base/src/components/PageTitle'
import Pagination from '@babelcoder/gatsby-theme-base/src/components/Pagination'
import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta'
import useTipsPath from '../hooks/useTipsPath'
import TipItem from './TipItem'

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flow-root;
    flex: 1;
    padding: ${theme.spacers.normal};
    background-color: ${theme.colors.neutral.gray100};
  `}
`

const Container = styled.div`
  ${({ theme }) => css`
    max-width: 1100px;
    margin: 0 auto;

    ${up('small')} {
      padding: 0 ${theme.spacers.large};
    }
  `}
`

const TipList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1rem;

  ${up('large')} {
    grid-auto-rows: 1fr;
  }
`

export default function Tips({ tips, currentPage, totalPages }) {
  const { siteUrl, siteName } = useSiteMeta()
  const { basePath } = useTipsPath()
  const { cover } = useStaticQuery(graphql`
    query {
      cover: imageSharp(original: { src: { regex: "/tip-cover/" } }) {
        fixed(width: 730, height: 415, quality: 50, webpQuality: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  `)
  const title = `รวม Tips ทั้งหมดสำหรับการเขียนโปรแกรม`
  const desc = `รวมเทคนิคการโปรแกรมภาษาต่าง ๆ และแนวคิดที่เกี่ยวข้องของ ${siteName}`
  const {
    src: coverImageURL,
    width: coverImageWidth,
    height: coverImageHeight,
  } = cover.fixed
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'Blog',
    name: title,
    description: desc,
    url: toSingleSlash(`${siteUrl}/${basePath}`),
    sameAs: 'https://facebook.com/babelcoder',
    inLanguage: 'th-TH',
    image: toSingleSlash(`${siteUrl}${coverImageURL}`),
  }

  return (
    <>
      <SEO
        title={title}
        type="article"
        urlPath={`${basePath}${currentPage === 1 ? '' : `/p/${currentPage}`}`}
        desc={desc}
        coverImageWidth={coverImageWidth}
        coverImageHeight={coverImageHeight}
        coverImageURL={coverImageURL}
        schema={schema}
      ></SEO>
      <Wrapper>
        <Container>
          <PageTitle>Tips ทั้งหมด</PageTitle>
          <TipList>
            {tips.map(({ node: tip }) => (
              <TipItem key={tip.fields.slug} tip={tip}></TipItem>
            ))}
          </TipList>
          <Pagination
            basePath={basePath}
            currentPage={currentPage}
            totalPages={totalPages}
          ></Pagination>
        </Container>
      </Wrapper>
    </>
  )
}
