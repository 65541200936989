import { useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import getDefaultOptions from '../utils/default-options'
import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash'

function useTipsPath() {
  const { sitePlugin } = useStaticQuery(graphql`
    query {
      sitePlugin(name: { eq: "@babelcoder/gatsby-theme-tips" }) {
        pluginOptions {
          basePath
        }
      }
    }
  `)
  const { basePath } = getDefaultOptions(sitePlugin.pluginOptions)
  const getTipPath = useCallback(
    (slug) => toSingleSlash(`${basePath}/${slug}`),
    [basePath]
  )

  return {
    basePath,
    getTipPath,
  }
}

export default useTipsPath
